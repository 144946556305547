/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.4)',
    height: '100%',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  btnRoot: {
    width: '100%',
    height: 32,
    paddingLeft: `0px !important`,
  },
  btnLabel: {
    textAlign: 'left',
    color: 'rgba(0,0,0,0.6)',
    fontSize: 16,
    width: '100%',
    fontWeight: 400,
  },
  popupContainer: {
    width: 500,
    minHeight: 300,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  chipRow: {
    marginTop: theme.spacing(2),
    flexWrap: 'wrap',
    display: 'flex',
  },
  chipRoot: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 16,
  },
  optionText: {
    cursor: 'pointer',
    color: '#0c1230',
    marginLeft: 8,

    '&:hover': {
      color: '#0c1230',
      opacity: 0.8,
    },
  },
  searchContainer: {
    width: '100%',
  }
});

class BrandDropDownSelect extends Component {
  constructor(Props) {
    super(Props);
    this.state = {
      anchorEl: null,
      isOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }


  handleClick(e) {
    this.setState({ anchorEl: e.currentTarget });
  }

  SelectAllBrands() {
    const { brandList, brandListOnChange } = this.props;
    brandListOnChange(brandList);
  }

  deleteAllBrands() {
    const { brandListOnChange } = this.props;
    brandListOnChange([]);
  }

  onChange(e, value) {
    const { brandListOnChange } = this.props;
    brandListOnChange([value]);
  }

  render() {
    const { classes, selectedBrandList, brandListOnDelete, brandList, loading } = this.props;
    const { anchorEl, isOpen } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'shop-popover' : undefined;

    return (
      <div className={classes.root}>
        <div className={classes.label}>貨品子類別</div>
        <Button
          classes={{ root: classes.btnRoot, label: classes.btnLabel }}
          onClick={this.handleClick}
          disabled={loading}
        >
          {selectedBrandList.length > 0
            ? (
              <Badge badgeContent={selectedBrandList.length} color="secondary">
                {`子類別`}
              </Badge>
            )
            : <div className={classes.btnLabel}>子類別</div>}
          {loading && <CircularProgress size={10} />}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => this.setState({ anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper className={classes.popupContainer}>
            <div className={classes.labelRow}>
              <div
                aria-hidden="true"
                className={classes.optionText}
                onClick={() => this.SelectAllBrands()}
              >
                類別全選
              </div>
              <div
                aria-hidden="true"
                className={classes.optionText}
                onClick={() => this.deleteAllBrands()}
              >
                取消選擇
              </div>
            </div>
            <div className={classes.searchContainer}>
              <Autocomplete
                open={isOpen}
                onOpen={() => this.setState({ isOpen: true })}
                openOnFocus
                onClose={() => this.setState({ isOpen: false })}
                options={brandList}
                value={null}
                getOptionLabel={option => option.dpd_brand}
                classes={{
                  root: classes.autoCompleteRoot,
                }}
                onChange={this.onChange}
                selectOnFocus
                inputValue=""
                renderInput={params => (
                  <TextField
                    {...params}
                    label="子類別"
                  />
                )}
              />
            </div>

            <div className={classes.chipRow}>
              {selectedBrandList && selectedBrandList.length > 0
                ? selectedBrandList.map(item => (
                  <Chip
                    key={item}
                    label={item}
                    onDelete={() => brandListOnDelete(item)}
                    classes={{ root: classes.chipRoot }}
                  />
                ))
                : null}
            </div>
          </Paper>
        </Popover>
      </div>
    );
  }
}

BrandDropDownSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  brandListOnChange: PropTypes.func.isRequired,
  selectedBrandList: PropTypes.array.isRequired,
  brandListOnDelete: PropTypes.func.isRequired,
  brandList: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

export default withRouter(withStyles(styles)(BrandDropDownSelect));
