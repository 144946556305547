/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.4)',
    height: '100%',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  btnRoot: {
    width: '100%',
    height: 32,
    paddingLeft: `0px !important`,
  },
  btnLabel: {
    textAlign: 'left',
    color: 'rgba(0,0,0,0.6)',
    fontSize: 16,
    width: '100%',
    fontWeight: 400,
  },
  popupContainer: {
    width: 500,
    minHeight: 300,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  chipRow: {
    marginTop: theme.spacing(2),
    flexWrap: 'wrap',
    display: 'flex',
  },
  chipRoot: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 16,
  },
  optionText: {
    cursor: 'pointer',
    color: '#0c1230',
    marginLeft: 8,

    '&:hover': {
      color: '#0c1230',
      opacity: 0.8,
    },
  },
  searchContainer: {
    width: '100%',
  }
});

class CategoryDropDownSelect extends Component {
  constructor(Props) {
    super(Props);
    this.state = {
      anchorEl: null,
      isOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }


  handleClick(e) {
    this.setState({ anchorEl: e.currentTarget });
  }

  SelectAllCategory() {
    const categorys = localStorage.getItem('pf-category')
      ? JSON.parse(localStorage.getItem('pf-category'))
      : [];
    const { catListOnChange } = this.props;
    catListOnChange(categorys);
  }

  deleteAllCategory() {
    const { catListOnChange } = this.props;
    catListOnChange([]);
  }

  onChange(e, value) {
    const { catListOnChange } = this.props;
    catListOnChange([value]);
  }

  render() {
    const { classes, selectedCatList, catListOnDelete } = this.props;
    const { anchorEl, isOpen } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'shop-popover' : undefined;
    const categorys = localStorage.getItem('pf-category')
      ? JSON.parse(localStorage.getItem('pf-category'))
      : [];
    // console.log(categorys)
    return (
      <div className={classes.root}>
        <div className={classes.label}>貨品類別</div>
        <Button
          classes={{ root: classes.btnRoot, label: classes.btnLabel }}
          onClick={this.handleClick}
        >
          {selectedCatList.length > 0
            ? (
              <Badge badgeContent={selectedCatList.length} color="secondary">
                {`類別`}
              </Badge>
            )
            : <div className={classes.btnLabel}>類別</div>}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => this.setState({ anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper className={classes.popupContainer}>
            <div className={classes.labelRow}>
              <div
                aria-hidden="true"
                className={classes.optionText}
                onClick={() => this.SelectAllCategory()}
              >
                類別全選
              </div>
              <div
                aria-hidden="true"
                className={classes.optionText}
                onClick={() => this.deleteAllCategory()}
              >
                取消選擇
              </div>
            </div>
            <div className={classes.searchContainer}>
              <Autocomplete
                open={isOpen}
                onOpen={() => this.setState({ isOpen: true })}
                openOnFocus
                onClose={() => this.setState({ isOpen: false })}
                options={categorys}
                value={null}
                getOptionLabel={option => option.dCA_NAME}
                classes={{
                  root: classes.autoCompleteRoot,
                }}
                onChange={this.onChange}
                selectOnFocus
                inputValue=""
                renderInput={params => (
                  <TextField
                    {...params}
                    label="類別"
                  />
                )}
              />
            </div>
            
            <div className={classes.chipRow}>
              {selectedCatList && selectedCatList.length > 0
                ? selectedCatList.map(item => (
                  <Chip
                    key={item.dCA_NO}
                    label={item.dCA_NAME}
                    onDelete={() => catListOnDelete(item)}
                    classes={{ root: classes.chipRoot }}
                  />
                ))
                : null}
            </div>
          </Paper>
        </Popover>
      </div>
    );
  }
}

CategoryDropDownSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  catListOnChange: PropTypes.func.isRequired,
  selectedCatList: PropTypes.array.isRequired,
  catListOnDelete: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(CategoryDropDownSelect));
