import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';

import Input from '../../Forms/FormInput';
import {
  addMinimunSetting,
  batchUpdateMinimunSetting,
} from '../../../utils/api';

const Schema = Yup.object().shape({
  shopid: Yup.string().required('必填項目'),
  productid: Yup.string().required('必填項目'),
  minimumStorage: Yup.number()
    .min(0, '最低存倉量為0')
    .required('必填項目'),
});

class StorageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formLoading: false,
    };
  }

  async onSubmit(values) {
    const { initValues, formSubmit } = this.props;
    this.setState({ loading: true });
    try {
      const parmas = {
        shopid: initValues.dSF_STO,
        productid: initValues.dSF_PROD,
        minimumStorage: values.minimumStorage,
        isBoxUnit: values.isBoxUnit === 'null' ? null : values.isBoxUnit,
      };
      await addMinimunSetting(parmas);
      toast('成功編輯最低存倉量');
      this.setState({ loading: false });
      formSubmit();
    } catch (err) {
      toast('編輯最低存倉量失敗，請重試。');
      this.setState({ loading: false });
    }
  }

  async batchUpdate(values) {
    const { batchUpdateItems, formSubmit } = this.props;
    this.setState({ loading: true });
    try {
      const paramsArray = [];
      Object.keys(batchUpdateItems).forEach(key => {
        const parmas = {
          shopid: batchUpdateItems[key].dSF_STO,
          productid: batchUpdateItems[key].dSF_PROD,
          minimumStorage: values.minimumStorage,
          isBoxUnit: values.isBoxUnit === 'null' ? null : values.isBoxUnit,
        };
        paramsArray.push(parmas);
      });

      await batchUpdateMinimunSetting(paramsArray);
      toast('成功批量編輯最低存倉量');
      this.setState({ loading: false });
      formSubmit();
    } catch (err) {
      toast('批量編輯最低存倉量失敗，請重試。');
      this.setState({ loading: false });
    }
  }

  render() {
    const { classes, initValues, batchUpdateItems } = this.props;
    const { loading, formLoading } = this.state;
    if (formLoading) {
      return (
        <div className={classes.root}>
          <CircularProgress size={30} color="primary" />
        </div>
      );
    }
    if (initValues && Object.keys(initValues).length > 0) {
      const form = {
        shopid: initValues.Store
          ? initValues.Store.dSTO_NAME // for display
          : initValues.dSF_STO,
        productid: initValues.Product
          ? `${initValues.dSF_PROD} ${initValues.Product.dPD_NAME}`
          : initValues.dSF_PROD,
        minimumStorage:
          initValues.Minimum && initValues.Minimum.minimumStorage
            ? initValues.Minimum.minimumStorage
            : 0,
        isBoxUnit:
          initValues.Minimum && initValues.Minimum.isBoxUnit !== null
            ? initValues.Minimum.isBoxUnit
            : 'null',
      };
      return (
        <Formik
          initialValues={form}
          validationSchema={Schema}
          onSubmit={(values, formikBag) => this.onSubmit(values, formikBag)}
        >
          {({ values, handleBlur, handleChange, touched, errors }) => (
            <Form className={classes.root}>
              <div className={classes.formWrapper}>
                <Input
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  fieldName="shopid"
                  label="店鋪"
                  placeholder="店鋪"
                  width="100%"
                  disableAutoComplete
                  disabled
                />
                <Input
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  fieldName="productid"
                  label="貨品"
                  placeholder="貨品"
                  width="100%"
                  disableAutoComplete
                  disabled
                />
                <Input
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  fieldName="minimumStorage"
                  label="最低存倉量"
                  placeholder="最低存倉量"
                  width="100%"
                  disableAutoComplete
                  formType="number"
                />
                <Button
                  type="submit"
                  style={{ width: '100%', marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  <div>
                    {loading ? (
                      <CircularProgress color="primary" size={22} />
                    ) : (
                      '提交'
                    )}
                  </div>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      );
    }

    if (batchUpdateItems && Object.keys(batchUpdateItems).length > 0) {
      const form = {
        minimumStorage: 0,
        isBoxUnit: 'null',
      };
      return (
        <Formik
          initialValues={form}
          validationSchema={Yup.object().shape({
            minimumStorage: Yup.number()
              .min(0, '最低存倉量為0')
              .required('必填項目'),
          })}
          onSubmit={values => this.batchUpdate(values)}
        >
          {({ values, handleBlur, handleChange, touched, errors }) => (
            <Form className={classes.root}>
              <div className={classes.formWrapper}>
                <Input
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  fieldName="minimumStorage"
                  label="最低存倉量"
                  placeholder="最低存倉量"
                  width="100%"
                  disableAutoComplete
                  formType="number"
                />
                <Button
                  type="submit"
                  style={{ width: '100%', marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  <div>
                    {loading ? (
                      <CircularProgress color="primary" size={22} />
                    ) : (
                      '提交'
                    )}
                  </div>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      );
    }

    return null;
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'Column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  gridRowContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px !important',
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 16,
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 500,
    // height: 500,
    margin: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  fieldCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  headerTextRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginBottom: 20,
    marginTop: 10,
    width: 640,
  },
  bubbleWrapper: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: '#ddd',
  },
  bubbleText: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
  },
  headerText: {
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginLeft: 20,
  },
  addressCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 30,
  },
  addressLink: {
    fontSize: 14,
    color: '#2e2e2e',
    fontWeight: 700,
  },
  addressTime: {
    fontSize: 16,
    color: 'rgba(51,51,51,0.6)',
    fontWeight: 500,
    textAlign: 'left',
  },
  loadingBtn: {
    width: '100%',
    backgroundColor: '#2478FF',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 20,
    height: 40,
    '&:hover': {
      backgroundColor: 'rgba(36, 120, 255, 0.8)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteButton: {
    width: '100%',
    backgroundColor: '#e74c3c',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 24,
    height: 48,
    '&:hover': {
      backgroundColor: '#c0392b',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  btn: {
    width: '100%',
  },
});

StorageForm.propTypes = {
  classes: PropTypes.object.isRequired,
  formSubmit: PropTypes.func.isRequired,
  initValues: PropTypes.object,
  batchUpdateItems: PropTypes.object,
};

export default withStyles(styles)(StorageForm);
