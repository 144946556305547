import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StorefrontIcon from '@material-ui/icons/Storefront';
import StorageIcon from '@material-ui/icons/Storage';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PeopleIcon from '@material-ui/icons/People';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ArchiveIcon from '@material-ui/icons/Archive';

import MenuItemButton from './MenuItemButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 0,
    margin: '0px 0px',
  },
  menuItem: {
    width: '100%',
    padding: '8px 16px',
  },
  links: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  },
  aciveLinks: {
    borderRight: `4px solid ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(63, 81, 181, 0.1)',
  },
  itemRow: {
    display: 'flex',
    alignItems: 'cener',
    justifyContent: 'flex-start',
  },
}));

function MenuItems({ setOpen }) {
  const style = useStyles();
  const profile = localStorage.getItem('profile')
    ? JSON.parse(localStorage.getItem('profile'))
    : {};
  const { type } = profile;

  const renderQueryParams = key => {
    const query = localStorage.getItem(key);
    return query;
  };

  return (
    <ul className={style.root}>
      <NavLink
        to={`/productRecord/?${renderQueryParams('productRecord')}`}
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <MonetizationOnIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="貨品銷售紀錄"
        />
      </NavLink>

      {type !== 'frontline' ? (
        <NavLink
          to={`/topsales/?${renderQueryParams('topsales')}`}
          className={style.links}
          activeClassName={style.aciveLinks}
          onClick={() => setOpen(false)}
        >
          <MenuItemButton
            icon={
              <EqualizerIcon
                style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
              />
            }
            label="貨品銷售排行"
          />
        </NavLink>
      ) : null}

      {type !== 'frontline' ? (
        <NavLink
          to={`/shopSellRecord/?${renderQueryParams('shopSellRecord')}`}
          className={style.links}
          activeClassName={style.aciveLinks}
          onClick={() => setOpen(false)}
        >
          <MenuItemButton
            icon={
              <StorefrontIcon
                style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
              />
            }
            label="店鋪銷售紀錄"
          />
        </NavLink>
      ) : null}

      <NavLink
        to={`/storageRecord/?${renderQueryParams('storageRecord')}`}
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <StorageIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="即時倉存紀錄"
        />
      </NavLink>

      {type !== 'frontline' ? (
        <NavLink
          to={`/shipment/?${renderQueryParams('shipment')}`}
          className={style.links}
          activeClassName={style.aciveLinks}
          onClick={() => setOpen(false)}
        >
          <MenuItemButton
            icon={
              <LocalShippingIcon
                style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
              />
            }
            label="走貨紀錄"
          />
        </NavLink>
      ) : null}

      {type !== 'frontline' ? (
        <NavLink
          to={`/instore?${renderQueryParams('instore')}`}
          className={style.links}
          activeClassName={style.aciveLinks}
          onClick={() => setOpen(false)}
        >
          <MenuItemButton
            icon={
              <ArchiveIcon
                style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
              />
            }
            label="收貨紀錄"
          />
        </NavLink>
      ) : null}

      {type !== 'frontline' ? (
        <NavLink
          to="/lowestStorage"
          className={style.links}
          activeClassName={style.aciveLinks}
          onClick={() => setOpen(false)}
        >
          <MenuItemButton
            icon={
              <ShowChartIcon
                style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
              />
            }
            label="最低存倉量"
          />
        </NavLink>
      ) : null}

      {type === 'admin' ? (
        <NavLink
          to="/staff"
          className={style.links}
          activeClassName={style.aciveLinks}
          onClick={() => setOpen(false)}
        >
          <MenuItemButton
            icon={
              <PeopleIcon
                style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
              />
            }
            label="員工管理"
          />
        </NavLink>
      ) : null}
    </ul>
  );
}

MenuItems.propTypes = {
  setOpen: PropTypes.func.isRequired,
};

export default MenuItems;
