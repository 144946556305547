/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { UNIT } from '../../../constant';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.4)',
    height: '100%',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  btnRoot: {
    width: '100%',
    height: 32,
    paddingLeft: `0px !important`,
  },
  btnLabel: {
    textAlign: 'left',
    color: 'rgba(0,0,0,0.6)',
    fontSize: 16,
    width: '100%',
    fontWeight: 400,
  },
  popupContainer: {
    width: 500,
    minHeight: 300,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  chipRow: {
    marginTop: theme.spacing(2),
    flexWrap: 'wrap',
    display: 'flex',
  },
  chipRoot: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  autoCompleteRoot: {
    width: '100%',
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  optionText: {
    cursor: 'pointer',
    color: '#0c1230',
    marginLeft: 8,

    '&:hover': {
      color: '#0c1230',
      opacity: 0.8,
    },
  }
});

class UnitDropDownSelect extends Component {
  constructor(Props) {
    super(Props);
    this.state = {
      anchorEl: null,
      selectOpen: false,
      searchValue: '',
    };
    this.handleClick = this.handleClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleClick(e) {
    this.setState({ anchorEl: e.currentTarget });
  }

  onChange(e, value) {
    const { unitListOnChange } = this.props;
    unitListOnChange([value]);
  }

  SelectAllUnit() {
    const { unitListOnChange } = this.props;
    unitListOnChange(UNIT);
  }

  deleteAllunit() {
    const { unitListOnChange } = this.props;
    unitListOnChange([]);
  }

  render() {
    const { classes, selectedUnitList, unitListOnDelete } = this.props;
    const { anchorEl, selectOpen, searchValue } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'shop-popover' : undefined;
    return (
      <div className={classes.root}>
        <div className={classes.label}>單位</div>
        <Button
          classes={{ root: classes.btnRoot, label: classes.btnLabel }}
          onClick={this.handleClick}
        >
          {selectedUnitList.length > 0
            ? (
              <Badge badgeContent={selectedUnitList.length} color="secondary">
                {`單位`}
              </Badge>
            )
            : <div className={classes.btnLabel}>單位</div>}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => this.setState({ anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper className={classes.popupContainer}>
            <div className={classes.labelRow}>
              <div
                aria-hidden="true"
                className={classes.optionText}
                onClick={() => this.SelectAllUnit()}
              >單位全選</div>
              <div
                aria-hidden="true"
                className={classes.optionText}
                onClick={() => this.deleteAllunit()}
              >
                取消選擇
              </div>
            </div>
            <Autocomplete
              open={selectOpen}
              onOpen={() => this.setState({ selectOpen: true })}
              openOnFocus
              onClose={() => this.setState({ selectOpen: false })}
              options={UNIT}
              value={null}
              getOptionLabel={option => option.value}
              // loading={loading}
              classes={{
                root: classes.autoCompleteRoot,
              }}
              onChange={this.onChange}
              selectOnFocus
              // onInputChange={this.onInputChange}
              noOptionsText="沒有搜尋結果"
              loadingText="搜尋中"
              inputValue={searchValue}
              renderInput={params => (
                <TextField
                  {...params}
                  label="單位"
                />
              )}
            />
            <div className={classes.chipRow}>
              {selectedUnitList && selectedUnitList.length > 0
                ? selectedUnitList.map(item => (
                  <Chip
                    key={item.value}
                    label={item.label}
                    onDelete={() => unitListOnDelete(item)}
                    classes={{ root: classes.chipRoot }}
                  />
                ))
                : null}
            </div>
          </Paper>
        </Popover>
      </div>
    );
  }
}

UnitDropDownSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  unitListOnChange: PropTypes.func.isRequired,
  selectedUnitList: PropTypes.array.isRequired,
  unitListOnDelete: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(UnitDropDownSelect));
