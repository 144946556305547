import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';

// layout
// import Main from 'components/MainLayout/Main';

// pages
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import StaffPage from 'containers/StaffPage';
import ProductRecordPage from 'containers/ProductRecordPage';
import Login from 'containers/LoginPage/Loadable';
import ShopSellRecord from '../ShopSellRecordPage';
import StorageRecordPage from '../StorageRecordPage';
import ShipmentPage from '../ShipmentPage';
import InstorePage from '../InstorePage';
import ProductSalesPage from '../ProductSalesPage';
import LowestStorageListPage from '../LowestStoragePage/LowestStorageListPage';

import PrivateRoute from '../../helpers/PrivateRoute';
import { AuthContext } from '../../context/authContext';
import GlobalStyle from '../../global-styles';
import {
  getCategoryShortList,
  getManagerShopList,
  getStoreShortList,
} from '../../utils/api';

export default function App() {
  let existingTokens = null;
  const localToken = localStorage.getItem('tokens');
  if (localToken && localToken !== 'undefined') {
    existingTokens = localToken;
  }
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const setTokens = token => {
    localStorage.setItem('tokens', token);
    setAuthTokens(token);
  };

  const initApp = useCallback(async () => {
    const filter = {
      limit: 100,
      offset: 0,
    };
    try {
      if (localToken) {
        const shopRes = await getStoreShortList({ ...filter, type: 'S' });
        const catRes = await getCategoryShortList(filter);
        const managerListRes = await getManagerShopList();
        localStorage.setItem('pf-store', JSON.stringify(shopRes.data.rows));
        localStorage.setItem('pf-category', JSON.stringify(catRes.data.rows));
        localStorage.setItem(
          'pf-managerList',
          JSON.stringify(managerListRes.data),
        );
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    initApp();
  }, []);

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Switch>
            <Route component={Login} exact path="/login" />
            <Route exact path="/">
              {authTokens ? (
                <Redirect to="/productRecord" />
              ) : (
                <Redirect to="login" />
              )}
            </Route>
            <PrivateRoute
              main
              path="/productRecord"
              component={ProductRecordPage}
            />
            <PrivateRoute main path="/topsales" component={ProductSalesPage} />
            <PrivateRoute
              main
              path="/shopSellRecord"
              component={ShopSellRecord}
            />
            <PrivateRoute main path="/shipment" component={ShipmentPage} />
            <PrivateRoute main path="/instore" component={InstorePage} />
            <PrivateRoute
              main
              path="/storageRecord"
              component={StorageRecordPage}
            />
            <PrivateRoute
              main
              path="/lowestStorage"
              component={LowestStorageListPage}
            />
            <PrivateRoute main path="/staff" component={StaffPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </MuiPickersUtilsProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <GlobalStyle />
      </Fragment>
    </AuthContext.Provider>
  );
}
