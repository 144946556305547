/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 0),
  },
  titleRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleCol: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    fontWeight: 600,
  },
  titleText: {
    fontSize: 16,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subTitleText: {
    fontSize: 11,
    color: theme.palette.primary.main,
  },
  shopName: {
    fontSize: 12,
    color: 'rgba(12, 18, 48, .8)',
  },
  statRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  statCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(0, 1),
    borderRight: '1px solid #eee',
  },
  lastStatCol: {
    borderRight: '1px solid white',
  },
  labelText: {
    fontSize: 12,
    color: 'rgba(12, 18, 48, 0.5)',
    width: '100%',
    testAlign: 'left',
  },
  numberText: {
    fontSize: 14,
    fontWeight: 600,
    width: '100%',
    testAlign: 'left',
  },
}));

const MobileListItem = ({ item }) => {
  const classes = useStyles();
  const { Product, ToStore, FromStore, dOS_QTY, dOS_COST } = item;
  const renderCost = () => {
    if (dOS_QTY && dOS_COST) {
      const q = parseFloat(dOS_QTY);
      const c = parseFloat(dOS_COST);
      return (q * c).toFixed(2);
    }
    return 0;
  };
  return (
    <div className={classes.root}>
      <div className={classes.titleRow}>
        <div className={classes.subTitleText}>{item.dOS_PROD}</div>
        <div className={classes.shopName}>{item.dOS_NO}</div>
      </div>
      <div className={classes.titleRow}>
        <div className={classes.titleCol}>{Product.dPD_NAME}</div>
      </div>
      <div className={classes.statRow}>
        <div className={classes.statCol}>
          <div className={classes.labelText}>走貨單數量</div>
          <div className={classes.numberText}>{item.dOS_QTY}</div>
        </div>
        <div className={classes.statCol}>
          <div className={classes.labelText}>出貨倉舖</div>
          <div className={classes.numberText}>{FromStore.dSTO_NAME}</div>
        </div>
        <div className={classes.statCol}>
          <div className={classes.labelText}>收貨倉舖</div>
          <div className={classes.numberText}>
            {ToStore ? ToStore.dSTO_NAME : '-'}
          </div>
        </div>
        <div className={classes.statCol}>
          <div className={classes.labelText}>走貨單成本</div>
          <div className={classes.numberText}>{renderCost()}</div>
        </div>
        <div className={clsx(classes.statCol, classes.lastStatCol)}>
          <div className={classes.labelText}>走貨日期時間</div>
          <div className={classes.numberText}>
            {moment(item.dOS_DATI).format('DD-MM-YYYY HH:mm')}
          </div>
        </div>
      </div>
    </div>
  );
};

MobileListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default MobileListItem;
