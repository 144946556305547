/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: 'pointer',
  },
  loadingRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    padding: theme.spacing(0),
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  titleRoot: {
    padding: theme.spacing(2),
    fontSize: '25px !important',
    fontWeight: 600,
  },
  modalContainer: {
    flex: 1,
  },
}));

export default function FullScreenDialog({
  open,
  handleClose,
  title,
  children,
  okBtnText,
  okBtnAction,
  closeOnPress,
  cancelOnPress,
  confirmLoading,
  cancelText,
  cancelDisable,
  okDisable,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.root }}
      TransitionComponent={Transition}
      fullScreen
    >
      <div className={classes.titleRoot}>{title || 'Title'}</div>
      <DialogContent classes={{ root: classes.content }}>
        {confirmLoading ? (
          <div className={classes.loadingRow}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <div className={classes.modalContainer}>{children}</div>
        )}
      </DialogContent>
      {handleClose ? (
        <IconButton classes={{ root: classes.closeBtn }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </Dialog>
  );
}

FullScreenDialog.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  okBtnText: PropTypes.string,
  okBtnAction: PropTypes.func,
  closeOnPress: PropTypes.func,
  cancelOnPress: PropTypes.func,
  confirmLoading: PropTypes.bool,
  cancelText: PropTypes.string,
  cancelDisable: PropTypes.bool,
  okDisable: PropTypes.bool,
};
