/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { getStoreShortList } from '../../../utils/api';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  autoCompleteRoot: {
    width: '100%',
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 16,
  },
  optionText: {
    cursor: 'pointer',
    color: '#0c1230',
    marginLeft: 8,

    '&:hover': {
      color: '#0c1230',
      opacity: 0.8,
    },
  },
  formControl: {
    width: '100%',
  },
});

const DEFAULT_FILTER = {
  limit: 100,
  offset: 0,
  q: '',
  type: 'S',
};

class ShopAutoCompleteSelect extends Component {
  constructor(Props) {
    super(Props);
    this.state = {
      open: false,
      searchValue: '',
      managerStoreList: [],
      shopList: [],
      filter: { ...DEFAULT_FILTER },
    };
    this.onChange = this.onChange.bind(this);
    this.shopOnChange = this.shopOnChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.debouncedSearchGetShopList = _.debounce(
      this.searchGetShopList,
      500,
    ).bind(this);
    this.loadMoreItems = this.loadMoreItems.bind(this);
  }

  componentDidMount() {
    this.getManagerShopList();
  }

  async getManagerShopList() {
    const profile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : {};
    const { type, staffid } = profile;
    if (type === 'manager') {
      const shopList = localStorage.getItem('pf-managerList')
        ? JSON.parse(localStorage.getItem('pf-managerList'))
        : [];
      const index = _.findIndex(shopList, shop => shop.dsa_no === staffid);
      if (index > -1) {
        const { dsa_memo } = shopList[index];
        try {
          const managerShopListRes = await getStoreShortList({
            storeids: dsa_memo,
          });
          const { data } = managerShopListRes;
          const { rows } = data;
          this.setState({ managerStoreList: rows });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  onChange(e, value) {
    const { shopOnSelect } = this.props;
    shopOnSelect([value]);
    this.setState({ open: false, searchValue: '' });
  }

  shopOnChange(e) {
    const { shopOnSelect } = this.props;
    shopOnSelect([e.target.value]);
  }

  SelectAllShop() {
    const profile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : {};
    const { type } = profile;
    let shops = [];
    const { shopOnSelect } = this.props;
    const { managerStoreList } = this.state;
    if (type === 'manager') {
      shops = managerStoreList;
    } else {
      shops = localStorage.getItem('pf-store')
        ? JSON.parse(localStorage.getItem('pf-store'))
        : [];
    }
    shopOnSelect(shops);
  }

  deleteAllShop() {
    const { shopOnSelect } = this.props;
    shopOnSelect([]);
  }

  onInputChange(event, value, reason) {
    const { filter } = this.state;
    const temp = { ...filter };
    if (reason === 'reset') {
      this.setState({ filter: { ...temp, q: '', type: 'S' } }, () =>
        this.debouncedSearchGetShopList(),
      );
    }
    if (reason === 'input') {
      if (value) {
        delete temp.type;
      } else {
        temp.type = 'S';
      }
      this.setState(
        {
          loading: true,
          filter: { ...temp, offset: 0, q: value },
          searchValue: value,
        },
        () => this.debouncedSearchGetShopList(),
      );
    }
  }

  async searchGetShopList() {
    const { filter } = this.state;
    try {
      const shopRes = await getStoreShortList(filter);
      this.setState({ shopList: shopRes.data.rows, loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  async loadMoreItems(event) {
    const listboxNode = event.currentTarget;
    if (
      Math.abs(
        listboxNode.scrollTop +
          listboxNode.clientHeight -
          listboxNode.scrollHeight,
      ) < 10
    ) {
      try {
        const { filter, shopList } = this.state;
        const filterObject = {
          ...filter,
          offset: filter.offset + filter.limit,
        };
        const shopRes = await getStoreShortList(filterObject);
        this.setState({
          shopList: shopList.concat(shopRes.data.rows),
          filter: filterObject,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  render() {
    const { classes, label, disabled } = this.props;
    const { open, loading, searchValue, managerStoreList } = this.state;
    const profile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : {};
    const { type, staffid } = profile;
    let shopOption = [];
    if (type === 'manager') {
      if (staffid) {
        shopOption = managerStoreList;
      }
    } else if (this.state.shopList && this.state.shopList.length > 0) {
      shopOption = [...this.state.shopList];
    } else {
      shopOption = localStorage.getItem('pf-store')
        ? JSON.parse(localStorage.getItem('pf-store'))
        : [];
    }
    return (
      <div className={classes.root}>
        {!disabled ? (
          <div className={classes.labelRow}>
            <div
              aria-hidden="true"
              className={classes.optionText}
              onClick={() => this.SelectAllShop()}
            >
              店舖全選
            </div>
            <div
              aria-hidden="true"
              className={classes.optionText}
              onClick={() => this.deleteAllShop()}
            >
              取消選擇
            </div>
          </div>
        ) : null}
        {type === 'manager' || type === 'frontline' ? (
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              {label || '銷售店鋪'}
            </InputLabel>
            <Select
              value=""
              onChange={this.shopOnChange}
              classes={{
                root: classes.selectRoot,
              }}
              style={{ width: '100%' }}
            >
              {shopOption.map(option => (
                <MenuItem value={option} key={option.dSTO_NO}>
                  {option.dSTO_NAME}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Autocomplete
            disabled={disabled}
            open={open}
            onOpen={() => this.setState({ open: true })}
            openOnFocus
            onClose={() => this.setState({ open: false })}
            options={shopOption}
            value={null}
            getOptionLabel={option => option.dSTO_NAME}
            loading={loading}
            classes={{
              root: classes.autoCompleteRoot,
            }}
            onChange={this.onChange}
            selectOnFocus
            onInputChange={this.onInputChange}
            inputValue={searchValue}
            renderInput={params => (
              <TextField {...params} label={label || '銷售店鋪'} />
            )}
          />
        )}
      </div>
    );
  }
}

ShopAutoCompleteSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  shopOnSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isRestrictedSearch: PropTypes.bool,
};

export default withRouter(withStyles(styles)(ShopAutoCompleteSelect));
