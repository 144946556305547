import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { UNIT } from '../../../constant';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  autoCompleteRoot: {
    width: '100%',
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 16,
  },
  optionText: {
    cursor: 'pointer',
    color: '#0c1230',
    marginLeft: 8,

    '&:hover': {
      color: '#0c1230',
      opacity: 0.8,
    },
  },
});

class UnitAutoComplete extends Component {
  constructor(Props) {
    super(Props);
    this.state = {
      open: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e, value) {
    const { unitOnSelect } = this.props;
    unitOnSelect([value]);
  }

  SelectAllUnit() {
    const { unitOnSelect } = this.props;
    unitOnSelect(UNIT);
  }

  deleteAllunit() {
    const { unitOnSelect } = this.props;
    unitOnSelect([]);
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.labelRow}>
          <div
            aria-hidden="true"
            className={classes.optionText}
            onClick={() => this.SelectAllUnit()}
          >
            單位全選
          </div>
          <div
            aria-hidden="true"
            className={classes.optionText}
            onClick={() => this.deleteAllunit()}
          >
            取消選擇
          </div>
        </div>
        <Autocomplete
          open={open}
          onOpen={() => this.setState({ open: true })}
          openOnFocus
          onClose={() => this.setState({ open: false })}
          options={UNIT}
          value={null}
          getOptionLabel={option => option.value}
          classes={{
            root: classes.autoCompleteRoot,
          }}
          onChange={this.onChange}
          selectOnFocus
          noOptionsText="沒有搜尋結果"
          loadingText="搜尋中"
          inputValue=""
          renderInput={params => (
            <TextField
              {...params}
              label="單位"
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      </div>
    );
  }
}

UnitAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  unitOnSelect: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(UnitAutoComplete));
