/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Record from './ShopSellRecord/Loadable';

function index() {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/shopSellRecord" component={Record} />
        <Route component={NotFoundPage} />
      </Switch>
    </React.Fragment>
  );
}

export default index;
