import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  pagination: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobilePagination: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  navBtn: {
    width: 80,
    height: 50,
  },
}));

function Pagination({
  count,
  page,
  onChangePage,
  rowsPerPage,
  onChangeRowsPerPage,
}) {
  const classes = useStyles();
  const renderDisable = () => {
    const totalPage = Math.ceil(count / rowsPerPage);
    if (page < totalPage - 1) {
      return false;
    }
    return true;
  };
  const renderPageNumber = () => {
    const start = page * rowsPerPage + 1;
    let end = 0;
    if (page * rowsPerPage + rowsPerPage >= count) {
      end = count;
    } else {
      end = page * rowsPerPage + rowsPerPage;
    }
    return `${start} - ${end} / ${count}`;
  };
  return (
    <div className={classes.root}>
      <TablePagination
        component="div"
        count={count}
        page={page}
        onChangePage={onChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPageOptions={[10, 15, 20, 50]}
        labelRowsPerPage="每頁顯示"
        classes={{ root: classes.pagination }}
      />
      <div className={classes.mobilePagination}>
        <Button
          className={classes.navBtn}
          disabled={page === 0}
          onClick={() => onChangePage(null, page - 1)}
        >
          <NavigateBeforeIcon />
        </Button>
        <div>{renderPageNumber()}</div>
        <Button
          className={classes.navBtn}
          disabled={renderDisable()}
          onClick={() => onChangePage(null, page + 1)}
        >
          <NavigateNextIcon />
        </Button>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
};

export default Pagination;
