/* eslint-disable consistent-return */
import axios from 'axios';

import { API_URL } from '../constant';

const axiosInstance = axios.create({
  baseURL: `${API_URL}v1/`,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(null, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('profile');
    localStorage.removeItem('tokens');
    window.location.href = '/login';
  } else if (error.response.status === 403) {
    localStorage.removeItem('profile');
    localStorage.removeItem('tokens');
    window.location.href = '/login';
  } else {
    return Promise.reject(error);
  }
});

export default axiosInstance;
