/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Staff from './Staff/Loadable';
import StaffForm from './StaffForm/Loadable';
import StaffEditForm from './StaffEditForm/Loadable';
import StaffPasswordForm from './StaffPasswordForm/Loadable';

function index() {
  const profile = localStorage.getItem('profile')
    ? JSON.parse(localStorage.getItem('profile'))
    : {};
  const { type } = profile;
  if (type !== 'admin') {
    return <Redirect to="/not-found" />;
  }
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/staff" component={Staff} />
        <Route exact path="/staff/new" component={StaffForm} />
        <Route exact path="/staff/edit/:userid" component={StaffEditForm} />
        <Route
          exact
          path="/staff/password/:userName/:userid"
          component={StaffPasswordForm}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </React.Fragment>
  );
}

export default index;
