import axios from '../service/axios';

// User
export const getUserList = filter => axios.get('/user/', { params: filter });

export const createNewUser = para => axios.post('/user', para);

export const changeOneUserProfile = (userid, values) =>
  axios.patch(`/user/${userid}`, values);

export const userLogin = para => axios.post('/user/login', para);

export const resetOneUserPassword = (userid, password) =>
  axios.post(`/user/${userid}/resetPassword`, password);

export const deleteOneUser = userid => axios.delete(`/user/${userid}`);

export const getUserProfile = () => axios.get('/user/profile');

export const getOneUserProfile = userid => axios.get(`/user/${userid}`);

export const userLogout = () => axios.post('/logout');

export const getManagerShopList = () => axios.get('/user/managerToShop');

export const uploadUserCSV = file => axios.post('/user/importCsv', file);

// Product
export const getProductList = filter =>
  axios.get('/product', { params: filter });

export const getProductStat = filter =>
  axios.get('/product/stat', { params: filter });

export const getProductShortList = filter =>
  axios.get('/product/shortlist', { params: filter });

export const getGroupProductList = filter =>
  axios.get('/product/groupbyproduct', { params: filter });

// Store
export const getStoreSellRecordList = filter =>
  axios.get('/store', { params: filter });

export const getStoreShortList = filter =>
  axios.get('/store/shortlist', { params: filter });

// stock
export const getStockList = filter => axios.get('/stock', { params: filter });

export const getStoreStat = filter =>
  axios.get('/store/stat', { params: filter });

export const getStockStat = filter =>
  axios.get('/stock/stat', { params: filter });

// category

export const getCategoryShortList = filter =>
  axios.get('/category/shortlist', { params: filter });

// shipment

export const getOutStoreList = filter =>
  axios.get('/outstore', { params: filter });

export const getOutStoreState = filter =>
  axios.get('/outstore/stat', { params: filter });

// instore
export const getInStoreList = filter =>
  axios.get('/instore', { params: filter });

export const getInStoreState = filter =>
  axios.get('/instore/stat', { params: filter });

// minimun

export const getMinimunSettingList = filter =>
  axios.get('/minimum', { params: filter });

export const addMinimunSetting = params => axios.post('/minimum', params);

export const batchUpdateMinimunSetting = params =>
  axios.post('/minimum/batchUpdateOrCreate', params);

export const uploadMinCSV = file => axios.post('/minimum/importCsv', file);

// brand
export const getProductBrandList = filter =>
  axios.get('/product/brand', { params: filter });
