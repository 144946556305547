/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { STORE_OPTIONS } from '../../../constant';
import { getStoreShortList } from '../../../utils/api';


const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.4)',
    height: '100%',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  btnRoot: {
    width: '100%',
    height: 32,
    paddingLeft: `0px !important`,
  },
  btnLabel: {
    textAlign: 'left',
    color: 'rgba(0,0,0,0.6)',
    fontSize: 16,
    width: '100%',
    fontWeight: 400,
  },
  popupContainer: {
    width: 500,
    minHeight: 300,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  chipRow: {
    marginTop: theme.spacing(2),
    flexWrap: 'wrap',
    display: 'flex',
  },
  chipRoot: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  searchRow: {
    width: '100%',
  },
  btnGroupRoot: {
    minWidth: 180,
  },
  selectedItem: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
  },
  selectedItemSecondary: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: 'white !important',
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 16,
  },
  optionText: {
    cursor: 'pointer',
    color: '#0c1230',
    marginLeft: 8,

    '&:hover': {
      color: '#0c1230',
      opacity: 0.8,
    },
  },
  selectRoot: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  }
});

const DEFAULT_FILTER = {
  limit: 100,
  offset: 0,
  q: '',
  type: 'S',
};

class StoreDropDownSelect extends Component {
  constructor(Props) {
    super(Props);
    this.state = {
      anchorEl: null,
      isOpen: false,
      loading: false,
      filter: { ...DEFAULT_FILTER },
      searchValue: '',
      managerStoreList: [],
      shopList: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.toggleOnChange = this.toggleOnChange.bind(this);
    this.managerOnChange = this.managerOnChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.shopOnChange = this.shopOnChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.debouncedSearchGetShopList = _.debounce(
      this.searchGetShopList,
      500,
    ).bind(this);
    this.loadMoreItems = this.loadMoreItems.bind(this);
  }

  componentDidMount() {
    this.getManagerShopList();
  }

  async getManagerShopList() {
    const profile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : {};
    const { type, staffid } = profile;
    if (type === 'manager') {
      const shopList = localStorage.getItem('pf-managerList')
        ? JSON.parse(localStorage.getItem('pf-managerList'))
        : [];
      const index = _.findIndex(shopList, shop => shop.dsa_no === staffid);
      if (index > -1) {
        const { dsa_memo } = shopList[index];
        try {
          const managerShopListRes = await getStoreShortList({ storeids: dsa_memo });
          const { data } = managerShopListRes;
          const { rows } = data;
          this.setState({ managerStoreList: rows });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  handleClick(e) {
    this.setState({ anchorEl: e.currentTarget });
  }

  toggleOnChange(e, selected) {
    const { toggleOnChange } = this.props;
    toggleOnChange(selected);
  }

  managerOnChange(e, value) {
    const { managerOnChange } = this.props;
    managerOnChange(value);
  }


  onChange(e, value) {
    const { shopListOnChange } = this.props;
    shopListOnChange([value]);
    this.setState({ isOpen: false, searchValue: '' });
  }

  shopOnChange(e) {
    const { shopListOnChange } = this.props;
    shopListOnChange([e.target.value]);
  }

  SelectAllShop() {
    const profile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : {};
    const { type } = profile;
    let shops = [];
    const { shopListOnChange } = this.props;
    const { managerStoreList } = this.state;
    if (type === 'manager') {
      shops = managerStoreList;
    } else {
      shops = localStorage.getItem('pf-store')
        ? JSON.parse(localStorage.getItem('pf-store'))
        : [];
    }
    shopListOnChange(shops);

  }

  deleteAllShop() {
    const { shopListOnChange } = this.props;
    shopListOnChange([]);
  }

  onInputChange(event, value, reason) {
    const { filter } = this.state;
    const temp = { ...filter };
    if (reason === 'reset') {
      this.setState({ filter: { ...temp, q: '', type: 'S' } }, () =>
        this.debouncedSearchGetShopList(),
      );
    }
    if (reason === 'input') {
      if (value) {
        delete temp.type;
      } else {
        temp.type = 'S';
      }
      this.setState(
        {
          loading: true,
          filter: { ...temp, offset: 0, q: value },
          searchValue: value
        },
        () => this.debouncedSearchGetShopList(),
      );
    }
  }

  async searchGetShopList() {
    const { filter } = this.state;
    try {
      const shopRes = await getStoreShortList(filter);
      this.setState({ shopList: shopRes.data.rows, loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  async loadMoreItems(event) {
    const listboxNode = event.currentTarget;
    if (
      Math.abs(
        listboxNode.scrollTop +
          listboxNode.clientHeight -
          listboxNode.scrollHeight,
      ) < 10
    ) {
      try {
        const { filter, shopList } = this.state;
        const filterObject = {
          ...filter,
          offset: filter.offset + filter.limit,
        };
        const shopRes = await getStoreShortList(filterObject);
        this.setState({
          shopList: shopList.concat(shopRes.data.rows),
          filter: filterObject,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  render() {
    const {
      classes,
      selectedShopList,
      shopListOnDelete,
      label,
      selectedType,
      searchOnly,
      selectedManager,
      managerShopList,
      isRestrictedSearch,
    } = this.props;
    const { managerStoreList } = this.state;
    const profile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : {};
    const { type, staffid } = profile;
    const { anchorEl, isOpen, loading, searchValue } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'shop-popover' : undefined;
    const renderBadgeNumber = () => {
      if (selectedType !== 'all' && selectedType) {
        return 1;
      }
      if (selectedShopList.length > 0) {
        return selectedShopList.length;
      }
      return 0;
    }
    let shopOption = [];
    if (type === 'manager') {
      if (staffid) {
        shopOption = managerStoreList;
      }
    } else if (this.state.shopList && this.state.shopList.length > 0) {
      shopOption = [...this.state.shopList];
    } else {
      shopOption = localStorage.getItem('pf-store') ? JSON.parse(localStorage.getItem('pf-store')) : [];
    }
    return (
      <div className={classes.root}>
        <div className={classes.label}>{label || '銷售店鋪'}</div>
        <Button
          classes={{ root: classes.btnRoot, label: classes.btnLabel }}
          onClick={this.handleClick}
        >
          {selectedShopList.length > 0 || (selectedType !== 'all' && selectedType)
            ? (
              <Badge badgeContent={renderBadgeNumber()} color="secondary">
                {`店鋪`}
              </Badge>
            )
            : <div className={classes.btnLabel}>店鋪</div>}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => this.setState({ anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper className={classes.popupContainer}>
            {searchOnly || isRestrictedSearch ? null : (
              <ToggleButtonGroup
                size="small"
                classes={{ root: classes.btnGroupRoot }}
                value={selectedType}
                exclusive
                onChange={this.toggleOnChange}
              >
                {STORE_OPTIONS.map(option => (
                  <ToggleButton
                    value={option.value}
                    key={option.value}
                    classes={{ selected: classes.selectedItem }}
                  >
                    {option.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}

            {selectedType === 'manager' && type !== 'manager' ? (
              <ToggleButtonGroup value={selectedManager} onChange={this.managerOnChange} size="small" style={{ marginTop: 10 }}>
                {managerShopList.map(manager => (
                  <ToggleButton
                    value={manager.dsa_name}
                    key={manager.dsa_name}
                    classes={{ selected: classes.selectedItemSecondary }}
                  >
                    {manager.dsa_name}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            ) : null}
            {selectedType === 'all' ? (
              <div className={classes.labelRow}>
                <div
                  aria-hidden="true"
                  className={classes.optionText}
                  onClick={() => this.SelectAllShop()}
                >
                  店舖全選
                </div>
                <div
                  aria-hidden="true"
                  className={classes.optionText}
                  onClick={() => this.deleteAllShop()}
                >
                  取消選擇
                </div>
              </div>
            ) : null}
            <div className={classes.searchRow}>

              {type === 'manager' || type === 'frontline' ? (
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    {label || '銷售店鋪'}
                  </InputLabel>
                  <Select
                    value=""
                    onChange={this.shopOnChange}
                    classes={{
                      root: classes.selectRoot,
                    }}
                    style={{ width: '100%' }}
                  >
                    {shopOption.map(option => (
                      <MenuItem
                        value={option}
                        key={option.dSTO_NO}
                      >
                        {option.dSTO_NAME}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Autocomplete
                  disabled={selectedType !== 'all' && !searchOnly}
                  open={isOpen}
                  onOpen={() => this.setState({ isOpen: true })}
                  openOnFocus
                  onClose={() => this.setState({ isOpen: false })}
                  options={shopOption}
                  value={null}
                  getOptionLabel={option => option.dSTO_NAME}
                  loading={loading}
                  classes={{
                    root: classes.autoCompleteRoot,
                  }}
                  onChange={this.onChange}
                  selectOnFocus
                  onInputChange={this.onInputChange}
                  inputValue={searchValue}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="銷售店鋪"
                    />
                  )}
                />
              )}

            </div>
            <div className={classes.chipRow}>
              {selectedShopList &&
              selectedShopList.length > 0 &&
              (selectedType === 'all' || searchOnly)
                ? selectedShopList.map(item => (
                  <Chip
                    key={item.dSTO_NO}
                    label={item.dSTO_NAME}
                    onDelete={() => shopListOnDelete(item)}
                    classes={{ root: classes.chipRoot }}
                  />
                ))
                : null}
              {selectedType === 'shop' ? (
                <Chip
                  label="主倉店鋪"
                  classes={{ root: classes.chipRoot }}
                />
              ) : null}
              {selectedType === 'warehouse' ? (
                <Chip
                  label="理想倉"
                  classes={{ root: classes.chipRoot }}
                />
              ) : null}
            </div>
          </Paper>
        </Popover>
      </div>
    );
  }
}

StoreDropDownSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  shopListOnChange: PropTypes.func.isRequired,
  selectedShopList: PropTypes.array.isRequired,
  shopListOnDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  toggleOnChange: PropTypes.func,
  selectedType: PropTypes.string,
  searchOnly: PropTypes.bool,
  managerShopList: PropTypes.array,
  managerOnChange: PropTypes.func,
  selectedManager: PropTypes.array,
  isRestrictedSearch: PropTypes.bool,
};

export default withStyles(styles)(StoreDropDownSelect);
