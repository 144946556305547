import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0 0 5px 0',
  },
  errmsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '5px 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
  descriptionText: {
    fontSize: 12,
    padding: '5px 0 5px 0',
    color: 'rgba(51,51,51,0.6)',
    width: '100%',
    textAlign: 'left',
  },
  input: {
    padding: '10px 18px',
    borderRadius: 6,
    // boxSizing: 'border-box',
    minHeight: 40,
    fontSize: 14,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:focus': {
      outline: 'none',
    },
  },
}));

const FormInput = ({
  width,
  label,
  values,
  fieldName,
  formType,
  errors,
  touched,
  handleChange,
  handleBlur,
  placeholder,
  textarea,
  disableAutoComplete,
  descriptionText,
  disabled,
}) => {
  const classes = useStyles();
  const inputType = formType || 'text';
  const setting = {
    value: values[fieldName] || '',
    type: inputType,
    onChange: handleChange,
    onBlur: handleBlur,
    className: classes.input,
    style: { width },
    placeholder,
    name: fieldName,
  };
  if (formType === 'number') {
    setting.step = 'any';
  }
  return (
    <div
      className={classes.root}
      style={textarea ? { width, marginBottom: 21 } : { width, height: 95 }}
    >
      <div className={classes.label}>{label}</div>
      {textarea ? (
        <textarea {...setting} style={{ height: 100, width }} />
      ) : (
        <input
          {...setting}
          style={{ height: 40, width }}
          autoComplete={disableAutoComplete ? 'new-password' : null}
          disabled={disabled}
        />
      )}
      {descriptionText ? (
        <div className={classes.descriptionText}>{descriptionText}</div>
      ) : null}
      {errors && touched && errors[fieldName] && touched[fieldName] ? (
        <div className={classes.errmsg}>{errors[fieldName]}</div>
      ) : null}
    </div>
  );
};

FormInput.defaultProps = {
  width: 280,
  formType: 'text',
  label: 'Input Title',
  placeholder: 'Input',
  textarea: false,
};

FormInput.propTypes = {
  width: PropTypes.any,
  formType: PropTypes.string,
  label: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  textarea: PropTypes.bool,
  disableAutoComplete: PropTypes.bool,
  descriptionText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormInput;
