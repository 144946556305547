/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import Shipment from './Shipment/Loadable';

function index() {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/shipment" component={Shipment} />
        <Route component={NotFoundPage} />
      </Switch>
    </React.Fragment>
  );
}

export default index;
