/* eslint-disable no-console */
/* eslint-disable react/no-multi-comp */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { cyan } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';

import { getProductBrandList, getProductShortList } from '../../utils/api';
import CategoryAutoComplete from '../Forms/CategoryAutoComplete';
import { UNIT_ALL, PRODUCT_STATUS } from '../../constant';
import BrandAutoComplete from '../Forms/BrandAutoComplete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    height: '100%',
    position: 'relative',
  },
  productListCol: {
    height: '70vh',
    overflow: 'auto',
    border: '1px solid #ddd',
    borderRadius: 5,
    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
  },
  productListItem: {
    padding: theme.spacing(1),
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
  productDetailsRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'rgba(51,51,51,0.6)',
    fontWeight: 400,
  },
  productTtitleRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  productTitleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent:'flex-start'
  },
  filterCol: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  productTitle: {
    fontWeight: 700,
    textAlign: 'left',
  },
  selectedText: {
    // color: '#2ecc71',
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  chipRow: {
    width: '100%',
    // padding: `0px ${theme.spacing(2)}px !important`,
    flexWrap: 'wrap',
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  chipRoot: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  selectedProductTitle: {
    fontSize: 20,
    fontWeight: 700,
  },
  emptyProductText: {
    fontSize: 16,
    fontWeight: 400,
    color: 'rgba(51,51,51,0.6)',
  },
  selectedproductContainer: {
    padding: `${theme.spacing(1)}px 0px`,
    borderBottom: '1px solid #ddd',
    marginTop: 20,
  },
  btnRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: `${theme.spacing(2)}px 0px`,
    backgroundColor: '#ffffff',
  },
  modalRoot: {
    minWidth: 600,
    position: 'relative',
    zIndex: 1501,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
      width: '100%',
      height: '100%',
      margin: 0,
    },
  },
  boldText: {
    fontSize: 14,
    fontWeight: 700,
    color: 'rgba(51,51,51,1)',
    marginRight: 5,
    minWidth: 33,
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  sellingBage: {
    fontSize: 10,
    fontWeight: 500,
    color: '#e74c3c',
    padding: '2px 4px',
    borderRadius: 2,
    border: '1px solid #e74c3c',
    marginLeft: 5,
  },
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(cyan.A700),
    backgroundColor: cyan.A700,
    '&:hover': {
      backgroundColor: cyan[800],
    },
  },
}))(Button);

class ProductSearchBox extends Component {
  constructor(Props) {
    super(Props);
    this.state = {
      productList: [],
      productCount: 0,
      searchValue: '',
      selectedUnit: '',
      productStatus: 'all',
      selectedCatList: [],
      selectedBrandList: [],
      brandList: [],
      loading: false,
      filterObject: {
        limit: 20,
        offset: 0,
        q: null,
        unit: null,
        catids: null,
      },
    };
    this.searchValueOnChange = this.searchValueOnChange.bind(this);
    this.unitOnChange = this.unitOnChange.bind(this);
    this.setSearch = _.debounce(this.debounchGetProductList, 500).bind(this);
    this.catListOnChange = this.catListOnChange.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.loadMoreItems = this.loadMoreItems.bind(this);
    this.selectAllOnClick = _.debounce(this.selectAllOnClick, 500).bind(this);
    this.brandListOnChange = this.brandListOnChange.bind(this);
    this.statusOnChange = this.statusOnChange.bind(this);
  }

  componentDidMount() {
    this.getProductList();
  }

  async getProductList() {
    const { filterObject, selectedCatList } = this.state;
    try {
      const productRes = await getProductShortList(filterObject);
      let tempBrandList = [];
      if (selectedCatList && selectedCatList.length > 0) {
        const brandRes = await getProductBrandList({
          limit: 999,
          offset: 0,
          catids: selectedCatList.map(cat => cat.dCA_NO).join(','),
        });
        tempBrandList = [...brandRes.data.rows];
      }
      this.setState({ productList: productRes.data.rows, productCount: productRes.data.count, brandList: tempBrandList });
    } catch (err) {
      console.log(err);
    }
  }

  searchValueOnChange(e) {
    const { filterObject } = this.state;
    const filter = {
      ...filterObject,
      q: e.target.value,
      offset: 0,
    };
    this.setState({ searchValue: e.target.value, filterObject: filter }, this.setSearch);
  }

  renderSelected(product) {
    const { classes, selectedProductList } = this.props;
    const temp = [...selectedProductList];
    const index = _.findIndex(temp, item => item.dPD_NO === product.dPD_NO);
    if (index !== -1) {
      return <div className={classes.selectedText}>已選擇</div>;
    }
    return null;
  }

  unitOnChange(e) {
    const { filterObject } = this.state;
    const filter = {
      ...filterObject,
      unit: e.target.value,
      offset: 0,
    };
    this.setState({ selectedUnit: e.target.value, filterObject: filter }, () => this.getProductList());
  }

  statusOnChange(e) {
    const { filterObject } = this.state;
    const filter = {
      ...filterObject,
      productStatus: e.target.value,
      offset: 0,
    };
    this.setState({ productStatus: e.target.value, filterObject: filter }, () => this.getProductList());
  }

  debounchGetProductList() {
    this.getProductList();
  }

  catListOnDelete(cat) {
    const { selectedCatList } = this.state;
    const temp = [...selectedCatList];
    const { filterObject } = this.state;
    const filter = { ...filterObject };
    if (cat) {
      const { dCA_NAME } = cat;
      const index = _.findIndex(temp, item => item.dCA_NAME === dCA_NAME);
      if (index === -1) {
        temp.push(cat);
      } else {
        temp.splice(index, 1);
      }
      const catids = temp.map(item => item.dCA_NO).join();
      filter.catids = catids;
      filter.offset = 0;
      delete filter.brand;
      this.setState({ selectedCatList: temp, filterObject: filter, selectedBrandList: [] }, () => this.getProductList());
    }
  }

  catListOnChange(cats) {
    const { selectedCatList } = this.state;
    const temp = [...selectedCatList];
    const { filterObject } = this.state;
    const filter = { ...filterObject };
    if (cats && cats.length > 0) {
      cats.forEach(category => {
        const { dCA_NAME } = category;
        const index = _.findIndex(temp, item => item.dCA_NAME === dCA_NAME);
        if (index === -1) {
          temp.push(category);
        }
      })

      const catids = temp.map(item => item.dCA_NO).join();
      filter.catids = catids;
      filter.offset = 0;
      delete filter.brand;
      this.setState({ selectedCatList: temp, filterObject: filter, selectedBrandList: [] }, () => this.getProductList());
    } else {
      filter.catids = '';
      filter.offset = 0;
      delete filter.brand;
      this.setState({ selectedCatList: [], filterObject: filter, selectedBrandList: [] }, () => this.getProductList());
    }
  }

  resetFilter() {
    const { history, location, clearProduct } = this.props;
    const { search } = location;
    const currentUrlParams = new URLSearchParams(search);
    currentUrlParams.delete('productids');
    const url = `${location.pathname}?${currentUrlParams.toString()}`;
    this.setState({
      selectedUnit: '',
      selectedCatList: [],
      searchValue: '',
      filterObject: { limit: 20, offset: 0 },
    }, () => {
      clearProduct();
      history.push(url);

    });
  }

  async loadMoreItems(event) {
    const listboxNode = event.currentTarget;
    if (
      Math.abs(
        listboxNode.scrollTop +
          listboxNode.clientHeight -
          listboxNode.scrollHeight,
      ) < 10
    ) {
      try {
        const { productList, filterObject } = this.state;
        const filter = {
          ...filterObject,
          offset: filterObject.offset + filterObject.limit,
        };
        const productRes = await getProductShortList(filter);
        this.setState({
          productList: productList.concat(productRes.data.rows),
          filterObject: filter,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  async selectAllOnClick() {
    this.setState({ loading: true });
    const { filterObject, productCount } = this.state;
    const { selectAll, selectedProductList } = this.props;
    const filter = { ...filterObject, limit: productCount };
    if (selectedProductList.length + productCount > 300) {
      toast('貨品數量不可多於300');
      this.setState({ loading: false });
    } else {
      try {
        const productRes = await getProductShortList(filter);
        if (selectAll) {
          selectAll(productRes.data.rows);
        }
        this.setState({ loading: false });
      } catch (err) {
        console.log(err);
        this.setState({ loading: false });
      }
    }
  }

  renderStopSellBadge(demo) {
    const { classes } = this.props;
    if (demo && demo.includes('(停)')) {
      return <div className={classes.sellingBage}>停售</div>
    }
    return null;
  }

  brandListOnChange(brands) {
    const { selectedBrandList } = this.state;
    const temp = [...selectedBrandList];
    const { filterObject } = this.state;
    const filter = { ...filterObject };
    if (brands && brands.length > 0) {
      brands.forEach(brand => {
        const { dpd_brand } = brand;
        const index = _.findIndex(temp, item => item === dpd_brand);
        if (index === -1) {
          temp.push(dpd_brand);
        }
      });
      const brandItems = temp.map(item => item).join();
      filter.brand = brandItems;
      filter.offset = 0;
      this.setState({ filterObject: filter, selectedBrandList: temp }, () => this.getProductList());
    } else {
      delete filter.brand;
      filter.offset = 0;
      this.setState({ filterObject: filter, selectedBrandList: [] }, () => this.getProductList());
    }
  }

  brandListOnDelete(brand) {
    const { selectedBrandList } = this.state;
    const temp = [...selectedBrandList];
    const { filterObject } = this.state;
    const filter = { ...filterObject };
    if (brand) {
      const { dpd_brand } = brand;
      const index = _.findIndex(temp, item => item.dpd_brand === dpd_brand);
      if (index === -1) {
        temp.push(dpd_brand);
      } else {
        temp.splice(index, 1);
      }
      const brandItems = temp.map(item => item).join();
      filter.brand = brandItems;
      filter.offset = 0;
      this.setState({ filterObject: filter, selectedBrandList: temp }, () => this.getProductList());
    }
  }

  render() {
    const {
      classes,
      handleClose,
      productOnSelect,
      selectedProductList,
      productOnDelete,
      open,
    } = this.props;
    const {
      productList,
      searchValue,
      selectedUnit,
      selectedCatList,
      productCount,
      loading,
      brandList,
      selectedBrandList,
      productStatus,
    } = this.state;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.modalRoot }}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">貨品清單</DialogTitle>
        <DialogContent classes={{ root: classes.contentRoot }}>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div className={classes.productListCol} onScroll={this.loadMoreItems}>
                  {productList.map(product => (
                    <div
                      className={classes.productListItem}
                      onClick={() => productOnSelect(product)}
                      key={product.dPD_NO}
                    >
                      <div className={classes.productTtitleRow}>
                        <div className={classes.productTitleContainer}>
                          <div className={classes.productTitle}>
                            {`${product.dPD_NO}. ${product.dPD_NAME}`}
                          </div>
                          {this.renderStopSellBadge(product.dPD_MEMO)}
                        </div>
                        {this.renderSelected(product)}
                      </div>
                      <div className={classes.productDetailsRow}>
                        <div>{`${product.dPD_SPRI}/${product.dPD_UNIT}`}</div>
                      </div>
                      <div className={classes.textRow}>
                        <div className={classes.boldText}>詳述:</div>
                        <div className={classes.descriptionText}>{product.dPD_MEMO}</div>
                      </div>
                      <div className={classes.textCol}>
                        <div className={classes.boldText}>裝箱規格:</div>
                        <div className={classes.descriptionText}>{product.dpd_cdf4 ? `${product.dpd_cdf4} ${product.dPD_UNIT}` : ''}</div>
                        <div className={classes.descriptionText}>{product.dpd_cdf9 ? `${product.dpd_cdf9}` : ''}</div>
                        <div>{!product.dpd_cdf4 && !product.dpd_cdf9 ? '-' : ''}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.filterCol}>
                  <div className={classes.selectedProductTitle}>篩選</div>
                  <TextField
                    label="貨品名稱/編號/描述"
                    value={searchValue}
                    onChange={this.searchValueOnChange}
                    className={classes.input}
                  />
                  <FormControl className={classes.formControl}>
                    <InputLabel>單位</InputLabel>
                    <Select value={selectedUnit} onChange={this.unitOnChange}>
                      {UNIT_ALL.map(stock => (
                        <MenuItem value={stock.value} key={stock.value}>
                          {stock.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className={classes.formControl} style={{ marginTop: 20 }}>
                    <InputLabel>貨品狀態</InputLabel>
                    <Select value={productStatus} onChange={this.statusOnChange}>
                      {PRODUCT_STATUS.map(stock => (
                        <MenuItem value={stock.value} key={stock.value}>
                          {stock.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <CategoryAutoComplete
                      catOnSelect={this.catListOnChange}
                    />
                    <div className={classes.chipRow}>
                      {selectedCatList && selectedCatList.length > 0
                        ? selectedCatList.map(item => (
                          <Chip
                            key={item.dCA_NO}
                            label={item.dCA_NAME}
                            onDelete={() => this.catListOnDelete(item)}
                            classes={{ root: classes.chipRoot }}
                          />
                        ))
                        : null}
                    </div>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <BrandAutoComplete
                      brandOnSelect={this.brandListOnChange}
                      brandList={brandList}
                    />
                    <div className={classes.chipRow}>
                      {selectedBrandList && selectedBrandList.length > 0
                        ? selectedBrandList.map(item => (
                          <Chip
                            key={item}
                            label={item}
                            onDelete={() => this.brandListOnDelete(item)}
                            classes={{ root: classes.chipRoot }}
                          />
                        ))
                        : null}
                    </div>
                  </FormControl>

                  <div className={classes.selectedproductContainer}>
                    <div className={classes.titleRow}>
                      <div className={classes.selectedProductTitle}>已選擇貨品 {selectedProductList.length > 0 ? `(${selectedProductList.length} / 300)` : null}</div>
                      {
                        selectedUnit || searchValue || selectedCatList.length > 0 || selectedBrandList > 0 ? (
                          <ColorButton onClick={this.selectAllOnClick}>
                            {loading ? <CircularProgress color="secondary" size={20} /> : `全選(${productCount}項)`}
                          </ColorButton>
                        ) : null
                      }

                    </div>

                    <div className={classes.chipRow}>
                      {selectedProductList && selectedProductList.length > 0
                        ? selectedProductList.map(item => (
                          <Chip
                            key={item.dPD_NO}
                            label={item.dPD_NAME}
                            onDelete={() => productOnDelete(item)}
                            classes={{ root: classes.chipRoot }}
                          />
                        ))
                        : <div className={classes.emptyProductText}>尚未有貨品</div>}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={this.resetFilter}
          >
            清除
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: 8 }}
            onClick={handleClose}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ProductSearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  productOnSelect: PropTypes.func.isRequired,
  selectedProductList: PropTypes.array.isRequired,
  productOnDelete: PropTypes.func.isRequired,
  clearProduct: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectAll: PropTypes.func,
};

export default withRouter(withStyles(styles)(ProductSearchBox));
