export const API_URL = process.env.API_URL
  ? process.env.API_URL
  : 'https://premierfood.appicidea.com/';

export const TABLE_PAGE_SIZE = 50;

export const TOAST_TIME = 5000;

export const IMAGE_URL = process.env.IMAGE_URL || '';

export const SHOP_JOYCE = [
  '02',
  '04',
  '09',
  '11',
  '16',
  '17',
  '18',
  '19',
  '27',
  '29',
  '33',
  '37',
  '38',
];
export const SHOP_ELAINE = [];

export const SHOP_MICHELLE = [
  '03',
  '05',
  '08',
  '12',
  '13',
  '21',
  '26',
  '28',
  '31',
  '34',
  '36',
  '40',
  '42',
];
export const SHOP_YUMI = [];

export const SHOP_YUK = ['06', '10', '20', '22', '24', '35', '39', '41'];
export const SHOP_BECKY = ['07', '14', '23', '25', '30', '32'];

export const STORE_OPTIONS = [
  { value: 'all', label: '搜尋' },
  { value: 'shop', label: '主倉店鋪' },
  { value: 'warehouse', label: '理想倉' },
  { value: 'manager', label: '經理' },
];

export const MANAGER_OPTIONS = [
  { value: 'yuk', label: 'Yuk' },
  { value: 'becky', label: 'Becky' },
  { value: 'joyce', label: 'Joyce' },
  { value: 'michelle', label: 'Michelle' },
];

export const UNIT = [
  { value: '罐', label: '罐' },
  { value: '支', label: '支' },
  { value: '件', label: '件' },
  { value: '包', label: '包' },
  { value: '本', label: '本' },
  { value: '把', label: '把' },
  { value: '卷', label: '卷' },
  { value: '枝', label: '枝' },
  { value: '杯', label: '杯' },
  { value: '架', label: '架' },
  { value: '個', label: '個' },
  { value: '套', label: '套' },
  { value: '隻', label: '隻' },
  { value: '張', label: '張' },
  { value: '條', label: '條' },
  { value: '盒', label: '盒' },
  { value: '單', label: '單' },
  { value: '部', label: '部' },
  { value: '塊', label: '塊' },
  { value: '碗', label: '碗' },
  { value: '對', label: '對' },
  { value: '餅', label: '餅' },
  { value: '磅', label: '磅' },
  { value: '樽', label: '樽' },
];

export const UNIT_ALL = [
  { value: '', label: '所有' },
  { value: '罐', label: '罐' },
  { value: '支', label: '支' },
  { value: '件', label: '件' },
  { value: '包', label: '包' },
  { value: '本', label: '本' },
  { value: '把', label: '把' },
  { value: '卷', label: '卷' },
  { value: '枝', label: '枝' },
  { value: '杯', label: '杯' },
  { value: '架', label: '架' },
  { value: '個', label: '個' },
  { value: '套', label: '套' },
  { value: '隻', label: '隻' },
  { value: '張', label: '張' },
  { value: '條', label: '條' },
  { value: '盒', label: '盒' },
  { value: '單', label: '單' },
  { value: '部', label: '部' },
  { value: '塊', label: '塊' },
  { value: '碗', label: '碗' },
  { value: '對', label: '對' },
  { value: '餅', label: '餅' },
  { value: '磅', label: '磅' },
  { value: '樽', label: '樽' },
];

export const PRODUCT_STATUS = [
  { label: '所有', value: 'all' },
  { label: '售賣中', value: 'onSale' },
  { label: '停售', value: 'discontinued' },
  { label: '清貨', value: 'clearance' },
  { label: '非售賣', value: 'notForSale' },
  { label: '網店', value: 'onlineShop' },
  { label: '缺貨', value: 'outOfStock' },
];
